import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import styles from './mental-health.module.scss';
import HeroImage from '../../components/hero-image/hero-image';
import MentalHealthSection from '../../components/trustaff-traveler/mental-health/mental-health-section';
import { Helmet } from 'react-helmet';

export default ({ data }) => {
  const getMentalHealthImageEdge = name => {
    return data.allFile.edges.find(e => e.node.name === name);
  };
  const ogImageUrlPath =
    getMentalHealthImageEdge('mental_health_hero').node.childImageSharp.fluid
      .src;
  const fullOgImageUrl = `https://www.trustaff.com${ogImageUrlPath}`;
  return (
    <>
      <Helmet>
        <title>Mental Health and Wellness - Trustaff</title>
        <meta charSet="utf-8" />
        <meta
          name="Description"
          content="We know how demanding a career in healthcare can be on your health, both physical and mental. Explore the many resources available to you from Trustaff to ensure our caregivers get the same personal attention they dedicate to their patients and their families.
          "
        />
        <meta property="og:title" content="Traveler perks &amp; discounts" />
        <meta
          property="og:description"
          content="We know how demanding a career in healthcare can be on your health, both physical and mental. Explore the many resources available to you from Trustaff to ensure our caregivers get the same personal attention they dedicate to their patients and their families.
          "
        />
        <meta property="og:image" content={fullOgImageUrl} />
        <meta
          property="og:url"
          content="https://www.trustaff.com/trustaff-traveler/mental-health/"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Trustaff" />
        <meta property="og:type" content="website" />
        <meta name="twitter:site" content="@Trustaff" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Layout>
        <HeroImage
          fluid={
            getMentalHealthImageEdge('mental_health_hero').node.childImageSharp
              .fluid
          }
        >
          <div className={'columns'}>
            <div className="column is-7 is-offset-1">
              <div className={styles.heroTextArea}>
                <h1 className={`title is-3 has-text-white`}>
                  We're here for you.
                </h1>
                <p className={`content`}>
                  Coming out of some of the most challenging years in healthcare
                  history, it’s no wonder that clinicians of all types are
                  feeling overwhelmed and overburdened. We understand, and we
                  want you to know you are not alone.
                </p>
                <p className={`content`}>
                  We know how demanding a career in healthcare can be on your
                  own personal health, both physical and mental. Explore the
                  many resources available to you from Trustaff to ensure our
                  caregivers get the same personal attention they dedicate to
                  their patients and their families.
                </p>
              </div>
            </div>
          </div>
        </HeroImage>
        <div className={`section ${styles.sectionNoSidePaddingMobile}`}>
          <MentalHealthSection
            fluidTop={
              getMentalHealthImageEdge('mental_health_1').node.childImageSharp
                .fluid
            }
            fluidBottom={
              getMentalHealthImageEdge('mental_health_2').node.childImageSharp
                .fluid
            }
          />
        </div>
      </Layout>
    </>
  );
};

export const pageQuery = graphql`
  {
    allFile(filter: { relativePath: { regex: "/^mental-health/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
