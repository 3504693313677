import React from 'react';
import Img from 'gatsby-image';
import styles from './mental-health-section.module.scss';

export default ({ fluidTop, fluidBottom }) => {
  return (
    <div className="columns">
      <div
        className={`columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile`}
      >
        <div className="column is-6">
          <h3 className={`title is-5 has-text-info`}>
            Mental Health &amp; Wellness Support
          </h3>
          <hr />
          <div className="content">
            <p className={`content ${styles.subsubheader}`}>
              Resources available through United Healthcare
            </p>
            <p className={`content`}>
              <a
                href="https://www.fastaff.com/sites/default/files/advocate4me-one-nurse-access-flier-uhc.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Resources List
              </a>
              <br />
              <a
                href="https://www.fastaff.com/sites/default/files/behavioral-health-virtual-visit-member-flier_5-14-19-uhc.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Behavioral Health Virtual Visit
              </a>
              <br />
              <a
                href="https://www.fastaff.com/sites/default/files/telemental_health_visits-uhc.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Telemental Health Info
              </a>
              <br />
              <a
                href="https://www.fastaff.com/sites/default/files/nurseline-employer-sell-sheet-uhc.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                NurseLine Info
              </a>
              <br />
              <a
                href="https://www.fastaff.com/sites/default/files/uhc_nurseline_flyer.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                NurseLine Access
              </a>
            </p>
            <p className={`content`}>
              United Healthcare Emotional Support Line: 1-866-342-6892
            </p>
            <p className={`content ${styles.subsubheader}`}>
              Resources available through Cigna
            </p>
            <p className={`content`}>
              <a
                href="https://www.fastaff.com/sites/default/files/cigna_life_assistance_program_flyer_0.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Life Assistance Program Info
              </a>
              <br />
              <a
                href="https://www.fastaff.com/sites/default/files/cigna_life_assistance_program.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Life Assistance Program Access
              </a>
            </p>
            <p className={`content`}>Cigna 24-hour Helpline: 1-866-912-1687</p>
            <hr />
            <p className={`content ${styles.subheader}`}>
              Mental Health Resources On The Go
            </p>
            <p className={`content ${styles.subsubheader}`}>
              Nationwide 3-Digit Suicide Prevention Hotline
            </p>
            <p className={`content`}>
              In July, states rolled out <b>988</b> as the new National Suicide
              Prevention Lifeline number, similar to how people can call 911 for
              emergencies. According to USA today, “All phone service providers
              will be required to connect callers who dial 988 to the lifeline
              starting July 16. The existing lifeline uses a 10-digit number,
              1-800-273-8255.”
              <br />
              <br />
              After dialing or texting 988, you'll be connected with a trained
              mental health professional at a local or regional crisis center.
              If your local center cannot connect you to a counselor, national
              backup centers can pick up the call. The lifeline is administered
              by the nonprofit Vibrant Emotional Health.
            </p>
            <p className={`content ${styles.subsubheader}`}>TalkSpace</p>
            <p className={`content`}>
              Offering a dedicated COVID-19 Instagram channel and therapist-lead
              Facebook groups, this app has wide ranging options for healthcare
              professionals on the go.{' '}
              <a
                href="https://www.talkspace.com/covid-resources"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more here
              </a>
            </p>
            <p className={`content ${styles.subsubheader}`}>
              NAMI Support for Healthcare Professionals
            </p>
            <p className={`content`}>
              The National Alliance on Mental Illness offers resources dedicated
              to healthcare workers who have been impacted by the events of the
              last year and a half. NAMI offers multiple resources to connect
              health care professionals with licensed mental health
              professionals at no cost, in addition to resources for individual
              and group support.{' '}
              <a
                href="https://www.nami.org/Your-Journey/Frontline-Professionals/Health-Care-Professionals"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more here
              </a>
            </p>
            <p className={`content ${styles.subsubheader}`}>Crisis Text Line</p>
            <p className={`content`}>
              Available 24/7, to anyone in any type of crisis, to provide free
              support via text.{' '}
              <a
                href="https://www.crisistextline.org/text-us/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more here
              </a>
            </p>
            <hr />
          </div>
        </div>
        <div className={`is-hidden-mobile column is-5 is-offset-1`}>
          <Img
            fluid={fluidTop}
            className={styles.aboutImageWrapper}
            alt="Mental Health Benefits"
          />
          <Img
            fluid={fluidBottom}
            className={`is-hidden-mobile ${styles.img2} ${styles.aboutImageWrapper}`}
            alt="Mental Health Assistance"
          />
        </div>
      </div>
    </div>
  );
};
